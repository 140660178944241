import React from 'react'
import tw from 'twin.macro'
import { Link, useStaticQuery, graphql } from "gatsby"
import { Container, LogoText, Layout, Seo } from './../components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ArticleListing = ({ node, index }) => {
  const image = getImage(node.frontmatter.hero)

  if (index === 0) {
    return (
      <Link to={`/${node.frontmatter.type}s/${node.slug}`} tw='col-span-2 relative text-white bg-gray-900 min-height[180px] padding-bottom[56.25%] text-center'>
        <div tw='absolute top-12 left-0 right-0 z-10 uppercase tracking-wider text-xs font-medium'>{node.frontmatter.eyebrow}</div>
        <div tw='absolute bottom-0 left-0 right-0 px-6 pb-8 md:(px-16 pb-10) lg:(px-24 pb-12) z-10'>
          <h2 tw='text-2xl font-bold'>{node.frontmatter.title}</h2>
        </div>
        <GatsbyImage
          image={image}
          alt={node.frontmatter.title}
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0, opacity: 0.5}}
        />
      </Link>
    )
  }
  return (
    <Link to={`/${node.frontmatter.type}s/${node.slug}`} tw='relative flex flex-col bg-white text-center dark:(background-color[#2b3035])'>
      <div tw='relative flex-none padding-bottom[56.25%]'>
        <GatsbyImage
          image={image}
          alt={node.frontmatter.title}
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0}}
        />
      </div>
      <div tw='py-4 px-2 md:(py-8 px-6) lg:(py-10 px-8)'>
        <div tw='relative z-10 uppercase tracking-wider text-xs font-medium text-gray-500 dark:(text-gray-400)'>{node.frontmatter.eyebrow}</div>
        <h2 tw='relative leading-tight mt-3 z-10 font-bold dark:(text-gray-100)'>{node.frontmatter.title}</h2>
      </div>
    </Link>
  )
}
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      posts: allMdx(
        filter: {frontmatter: {draft: {eq: false}}}
        sort: {fields: frontmatter___date, order: DESC}
      ) {
        nodes {
          slug
          id
          frontmatter {
            eyebrow
            title
            type
            hero {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo title="Home" />
      <div tw='p-8 text-center dark:(background-color[#272a2f])'>
        <div tw='flex justify-center mb-4'>
          <LogoText/>
        </div>
        {/* <ol tw='flex text-sm tracking-wider uppercase items-center justify-center space-x-4 dark:(text-gray-500)'> */}
        {/*   <li>All</li> */}
        {/*   <li>Articles</li> */}
        {/*   <li>Videos</li> */}
        {/*   <li>Tags</li> */}
        {/* </ol> */}
      </div>
      <div tw='px-4 md:(px-6) lg:(px-8) bg-gray-50 dark:(background-color[#272a2f])'>
        <div tw='py-8 md:(py-12) lg:(py-16) max-w-screen-xl mx-auto'>

          <div tw='grid grid-cols-2 gap-2 md:(grid-cols-3) lg:(grid-cols-4 gap-6)'>
            {
              data.posts.nodes.map((node, index) => (
                <ArticleListing key={node.id} node={node} index={index} />
              ))
            }
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
